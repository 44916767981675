import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from 'react-accessible-accordion';

const AccordionComponent = ({ questions }) => {
  return (
    <Accordion>
      {questions &&
        questions.map(el => (
          <AccordionItem key={el.heading}>
            <AccordionItemTitle>
              <h3 className="u-position-relative">
                {el.heading}
                <div className="accordion__arrow" role="presentation" />
              </h3>
            </AccordionItemTitle>
            <AccordionItemBody>
              <p>{el.description}</p>
            </AccordionItemBody>
          </AccordionItem>
        ))}
    </Accordion>
  );
};

AccordionComponent.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  ).isRequired
};

export default AccordionComponent;
